.SocialLink {
    grid-area: artist-social;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0;
}

.SocialLink-logo {
    width: 1.8rem;
    height: 1.8rem;
}

.SocialLink-marker {
}

.SocialLink-url {
    color: red;
    font-size: 1rem;
}