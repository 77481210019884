@import '../variables.scss';

.artist-name {
    grid-area: artist-name;
    padding: 0.4rem;
    border-bottom: 1px solid $red-main-color;
}

.artist-exhibitionaddress-header {
    grid-area: artist-exhibitionaddress-header;
    margin-top: 0.8rem;
}

.artist-exhibitionaddress-header h3 {
    color: #666;
}

.artist-craft {
    grid-area: artist-craft;
}

.artist-image-header {
    grid-area: artist-image-header;
    width: 100%;
    background-color: black;
}

.artist-image-container {
    height: 200px;
}

.artist-image-header img {
    height: 200px;
}

.artist-text {
    grid-area: artist-text;
    padding: 1rem;
    height: fit-content;
}

.artist-social-link {
    width: 2rem;
    height: 2rem;
}

.ArtistPage {
    display: grid;
    grid-template-areas: 
        "artist-image-header"
        "artist-name"
        "artist-exhibitionaddress-header"
        "artist-text"
        "artist-social"
        "artist-gallery";
}

@media (min-width: $breakpoint-desktop) {
    .artist-image-container {
        height: 400px;
    }

    .artist-image-header {
        border-radius: 9px 9px 0 0;
    }

    .artist-image-header img {
        height: 400px;
    }

    .ArtistPage {
        grid-template-areas:
            "artist-image-header"
            "artist-name"
            "artist-exhibitionaddress-header"
            "artist-text"
            "artist-social"
            "artist-gallery";
    }
}